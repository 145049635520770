// 万目云 IP授权
const IP = {
  //首页
  'tablist':[
    {tab:'明星IP'},
    {tab:'影视IP'},
    {tab:'游戏IP'},
    // {tab:'文旅IP'}
  ],
  'tabitem':[{id:1,content:[
                    {id:1,img:'https://h5.ophyer.cn/official_website/other/ip-1.jpg'},
                    {id:2,img:'https://h5.ophyer.cn/official_website/other/ip-2.jpg'},
                    {id:3,img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg'},
                    {id:4,img:'https://h5.ophyer.cn/official_website/other/ip-4.jpg'},
                    {id:5,img:'https://h5.ophyer.cn/official_website/other/ip-5.jpg'},
                    {id:6,img:'https://h5.ophyer.cn/official_website/other/ip-6.jpg'},
                    {id:7,img:'https://h5.ophyer.cn/official_website/other/ip-7.jpg'},
                    {id:8,img:'https://h5.ophyer.cn/official_website/other/ip-8.jpg'}
                    ]
            },
            {id:2,content:[
                    {id:1,img:'https://h5.ophyer.cn/official_website/case/mofaxueyuanzhi-img.jpg',anli:'mofaxueyuanzhi',title:'魔法学院之见习女巫',sub:'国内首部魔幻学院题材电影《魔法学院之见习女巫》该片是由新锐导演梁锋执导，张纬驰、李一锋、林雨凡、姚威...'},
                    {id:2,img:'https://h5.ophyer.cn/official_website/case/xiongrenzhuan-img.jpg',anli:'xiongrenzhuan',title:'《熊人传》',sub:'专门报道食品安全的主持人海涛，在一次和动物管理员女友约会的场景中，无意被动物园中被犯罪集团研究的北极...'},
                    {id:3,img:'https://h5.ophyer.cn/official_website/case/xingmiyuzhou-img.jpg',anli:'xingmiyuzhou',title:'《星迷宇宙》大电影',sub:'某公司老板三金是一个超级英雄幻想家，每天在办公室里勾勒着超级英雄梦。而他的员工却觉得他是一个有问题的...'},
            ]},

            {id:3,content:[
                    {id:1,img:'https://h5.ophyer.cn/official_website/case/jiangshizhazhiji-img.jpg',anli:'jiangshizhazhiji',title:'僵尸榨汁机',sub:'《僵尸榨汁机》是一款既幽默搞笑又略重口味的僵尸题材休闲手游，本作将经营与冒险的元素结合起来，形成了一...'},
                    {id:2,img:'https://h5.ophyer.cn/official_website/case/taotaoxiong-img.jpg',anli:'taotaoxiong',title:'《涛涛熊极速联盟》',sub:'《涛涛熊极速联盟》是一款使用Unity 3D引擎制作的，全3D卡通风格乱斗类赛车游戏...'},
                    {id:3,img:'https://h5.ophyer.cn/official_website/case/shousirenzhe-img.jpg',anli:'shousirenzhe',title:'寿司忍者之忍者突袭',sub:'《寿司忍者之忍者突袭》是一款高速跑酷动作游戏，游戏中只需要触摸点击屏幕这一个步骤便可简单的进行游戏...'},
            ]},

            {id:4,content:[
                    {id:1,img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'魔法学院之见习女巫',sub:'国内首部魔幻学院题材电影《魔法学院之见习女巫》该片是由新锐导演梁锋执导，张纬驰、李一锋、林雨凡、姚威...'},
                    {id:2,img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'《熊人传》',sub:'专门报道食品安全的主持人海涛，在一次和动物管理员女友约会的场景中，无意被动物园中被犯罪集团研究的北极...'},
                    {id:3,img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'《星迷宇宙》大电影',sub:'某公司老板三金是一个超级英雄幻想家，每天在办公室里勾勒着超级英雄梦。而他的员工却觉得他是一个有问题的...'},
                    ]
            },
      ],
  'list':[
    {img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'广泛的应用场景，赋能商业，打造差异化品牌IP',h2:'IP品牌运营',p:'虚拟人“活”灵“活”现，原本被动接受运营的<br/>消费者开始亲身参与交互，充分体验品牌IP魅<br/>力，拉动运营增长的全新只能触点'},
    {img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'数字创意产业崛起，内容为王的时代，得IP者得天下',h2:'虚拟导游',p:'针对景区导览、景区问讯等旅游场景需求，为游<br/>客提供景区导览、讲解等服务。帮助文旅品牌增<br/>强影响力，提供差异化服务，打造具有粘性的生态内容'},
    {img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'广泛的应用场景，赋能商业，打造差异化品牌IP',h2:'IP品牌运营',p:'虚拟人“活”灵“活”现，原本被动接受运营的<br/>消费者开始亲身参与交互，充分体验品牌IP魅<br/>力，拉动运营增长的全新只能触点'},
    {img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'数字创意产业崛起，内容为王的时代，得IP者得天下',h2:'虚拟导游',p:'针对景区导览、景区问讯等旅游场景需求，为游<br/>客提供景区导览、讲解等服务。帮助文旅品牌增<br/>强影响力，提供差异化服务，打造具有粘性的生态内容'},
    ],
  'cardslist': [
    {  src:'https://h5.ophyer.cn/official_website/other/ip-3.jpg', content:'第八届西塘汉服文化节',id:1},
    {  src:'https://h5.ophyer.cn/official_website/other/ip-3.jpg', content:'奥林匹克博览会',id:2},
    {  src:'https://h5.ophyer.cn/official_website/other/ip-3.jpg', content:'大美和谐 自在之境—千里江山图',id:3},
    {  src:'https://h5.ophyer.cn/official_website/other/ip-3.jpg', content:'为景区打造专属VR看景功能',id:4},
  ],
  'ProductCard':[
    {img:'https://h5.ophyer.cn/official_website/other/IPhy31.png',icon:require('../../public/img/icons/ip/IPicon21.png'),title:'政策扶持',content:'“十三五”规划将“数字创意产业”列为新一代五大新支柱之一；数字创意正逐步成为我国文化产业发展的新增长点'},
    {img:'https://h5.ophyer.cn/official_website/other/IPhy32.png',icon:require('../../public/img/icons/ip/IPicon22.png'),title:'本土IP崛起',content:'随着国产IP迅猛发展与国潮复兴，国人对本土IP的认可度越来越高，给本土IP开启变现潮提供了坚实的基础'},
    {img:'https://h5.ophyer.cn/official_website/other/IPhy33.png',icon:require('../../public/img/icons/ip/IPicon23.png'),title:'产业生态',content:'以IP为核心的文创产业正从内容融合向产业生态融合迈进,“IP+产业”全面融合、新技术广泛应用等方向推进'},
    {img:'https://h5.ophyer.cn/official_website/other/IPhy34.png',icon:require('../../public/img/icons/ip/IPicon24.png'),title:'市场需求大',content:'产品运营、教育、文旅、直播等各行业对IP的需求量逐步增长，数字创意产业逐步崛起'}
  ],
  'Pro_list':[
    {title:'IP品牌运营',content:'虚拟人“活”灵“活”现，原本被动接受运营的消费者开始亲身参与交互，充分体验品牌IP魅力，拉动运营增长的全新只能触点',img:'https://h5.ophyer.cn/official_website/other/IPsq3-1.png'},
    {title:'虚拟导游',content:'针对景区导览、景区问讯等旅游场景需求，为游客提供景区导览、讲解等服务。帮助文旅品牌增强影响力，提供差异化服务，打造具有粘性的生态内容',img:'https://h5.ophyer.cn/official_website/other/IPsq4-1.png'},
    {title:'虚拟客服',content:'IP 虚拟人化身虚拟客服，植入大屏一体机或Web页面，为用户提供问答服务。提供及时答复的同时，营造更加亲和自然的客服体验。',img:'https://h5.ophyer.cn/official_website/other/Ipsq3.png'},
    {title:'虚拟教师',content:'针对网络教学、在线解题等教育场景需求，IP 虚拟人化身为虚拟教师，植入平板或智慧教学屏等中小型硬件设备，为学生提供一对一的专属授课服务；降低教学人力成本，激发学生的学习兴趣',img:'https://h5.ophyer.cn/official_website/other/IPsq5-2.png'},
    {title:'虚拟主播',content:'针对直播、游戏讲解等媒体场景需求，IP 虚拟人化身为虚拟主播，为用户提供相应服务；利用虚拟主播，可降低直播的门槛、成本，打造更具话题和关注度的差异化品牌IP',img:'https://h5.ophyer.cn/official_website/other/IPsq2-1.png'},
    {title:'虚拟助手',content:'针对音乐播放、天气查询、闲聊对话等智能助手场景需求，IP 虚拟人化身虚拟助手，植入 IoT 硬件、手机 App 或车机等设备，为用户提供便捷的生活服务，成为能说会动的“全能型”智能助手。',img:'https://h5.ophyer.cn/official_website/other/IPsq1-1.png'},
  ],
  //解决方案
  'typeList':[
    {title:'衍生品授权',img:require('../../public/img/icons/ip/IPSolution1.png'),p:'将知识产权素材直接用于实物商品上'},
    {title:'外包装授权',img:require('../../public/img/icons/ip/IPSolution2.png'),p:'将知识产权素材用于其物流外包装、礼盒外包装等'},
    {title:'礼赠品授权',img:require('../../public/img/icons/ip/IPSolution3.png'),p:'将知识产权元素用于“非卖品”上，用于赠品'},
    {title:'运营授权',img:require('../../public/img/icons/ip/IPSolution4.png'),p:'将知识产权元素用于运营宣传'},
    {title:'数字虚拟授权',img:require('../../public/img/icons/ip/IPSolution5.png'),p:'将知识产权元素用于数字网络方面'},
    {title:'知识产权改编授权',img:require('../../public/img/icons/ip/IPSolution6.png'),p:'将某一知识产权改编为影视、动画、游戏等'},
    {title:'线下实体店授权',img:require('../../public/img/icons/ip/IPSolution7.png'),p:'将知识产权元素用于线下体验空间的主题场景营造'},
    // {title:'礼赠品授权',img:require('../../public/img/icons/ip/IPSolution8.png'),p:'将知识产权元素用于“非卖品”上，用于赠品'},
    {title:'主题乐园授权',img:require('../../public/img/icons/ip/IPSolution9.png'),p:'运用IP元素，打造完整的IP授权主题乐园'},
    // {title:'公共交通授权',img:require('../../public/img/icons/ip/IPSolution10.png'),p:'运用于公交车、地铁、飞机外部涂装以及内部装饰等'},
  ],
  'application':[
    {img:'https://h5.ophyer.cn/official_website/other/IPSolution2-1.png',content:'· AI人工智能技术 <br/>· 语音识别人机互动技术'},
    {img:'https://h5.ophyer.cn/official_website/other/IPSolution2-2.png',content:'· 5G远程数据传输技术<br/>· 幻影成像系统及全息投影技术<br/>· 传感介质应用技术'},
    {img:'https://h5.ophyer.cn/official_website/other/IPSolution2-3.png',content:'· 计算机视觉与图像识别技术<br/>· 移动端实时3D互动App开发<br/>· CG视觉数字艺术内容制作'},
    {img:'https://h5.ophyer.cn/official_website/other/IPSolution2-4.png',content:'· VR、AR、MR<br/>· 高清多通道投影融合系统<br/>· 实时渲染引擎技术'}
  ],
}

// 万目云 AI智能
const AI = {
    'items': [
        {
          tab: "人脸识别",
          title: "智能AI人脸识别",
          content:"运用计算机视觉技术，在无感状态下获取人脸图片，分析比较人脸视觉特征进行身份坚定",
          src:'https://h5.ophyer.cn/official_website/other/AIHome1-1.png'
        },
        {
          tab: "智能语音助手",
          title: "智能语音助手",
          content:"运用智能AI制定语音话术，帮助企业减少人工成本，助力企业获取用户问题解决最佳方案",
          src:'https://h5.ophyer.cn/official_website/other/AIHome1-2.png'
        },
        {
          tab: "智能客服",
          title: "智能客服",
          content:"基于智能AI系统，实现智能运营、智能外呼、在线客服等多种应用场景，帮助企业接生人力成本投入、提高服务质量、实现企业智能化运营",
          src:'https://h5.ophyer.cn/official_website/other/AIHome1-3.png'
        },
        {
          tab: "物体识别",
          title: "物体识别",
          content:"通过物体自动分析技术、对场景内的物体进行内容分析，以及场景的识别，获取相关信息。为企业解决图像或视频内容分析、拍照识图等多方面应用",
          src:'https://h5.ophyer.cn/official_website/other/AIHome1-4.png'
        },
    ],
    'advantageList': [
      {
        src: 'https://h5.ophyer.cn/official_website/other/AIHome2-1.png',
        title: "资深的AI专家顾问",
        content: "专属行业AI顾问，更了解您的需求，5年以上顾问经验提供7*24小时专属符文，为您解决行业内的一切智能AI问题"
      },
      {
        src: "https://h5.ophyer.cn/official_website/other/AIHome2-2.png",
        title: "标准流程无门槛操作",
        content: "多年深耕智能AI领域，打造智能AI行业标杆，无视企业门槛限制，多行业应用，专属对接"
      },
      {
        src: "https://h5.ophyer.cn/official_website/other/AIHome2-3.png",
        title: "多平台支撑",
        content: "支持IOS、Android手机系统，支持MAC、WindowsPC操作系统、支持H5、APP、小程序、Web等API对接"
      },
      {
        src: "https://h5.ophyer.cn/official_website/other/AIHome2-4.png",
        title: "高级智能平台策划",
        content: "10年资深研发团队，保证稳定性与功能匹配性，不做皮毛功夫，技术下沉"
      }
    ],
    'template': [
      {
        img: 'https://h5.ophyer.cn/official_website/other/AIHome3-1.png',
        title: '智能教育'
      },
      {
        img: 'https://h5.ophyer.cn/official_website/other/AIHome3-2.png',
        title: '智能医疗'
      },
      {
        img: 'https://h5.ophyer.cn/official_website/other/AIHome3-3.png',
        title: '智能零售'
      },
      {
        img: 'https://h5.ophyer.cn/official_website/other/AIHome3-4.png',
        title: '智能工业'
      },
      {
        img: 'https://h5.ophyer.cn/official_website/other/AIHome3-5.png',
        title: '企业服务'
      },
      {
        img: 'https://h5.ophyer.cn/official_website/other/AIHome3-6.png',
        title: '智能硬件'
      },
      {
        img: 'https://h5.ophyer.cn/official_website/other/AIHome3-7.png',
        title: '智能园区'
      },{
        img: 'https://h5.ophyer.cn/official_website/other/AIHome3-8.png',
        title: '信息服务'
      },
    ]
}

// 万目云 电商商城
const OnlineRetailersStore = {
  'items':[
    {img:'https://h5.ophyer.cn/official_website/other/store-QQ.png',title:'多平台一键卖货',tip:'微信、QQ、头条、抖音等多平台，一键卖货，全网引流'},
    {img:'https://h5.ophyer.cn/official_website/other/storeHome1-2.png',title:'快捷轻松经营',tip:'数十款装修模板，帮不同品类商家极速打造微商城多类目多店铺统一管理；商品、店铺一键搬家'},
    {img:'https://h5.ophyer.cn/official_website/other/storeHome1-3.png',title:'丰富运营玩法',tip:'优惠券、满减、秒杀、团购等多种工具，让老客户重复买，老客户带新客户'},
    {img:'https://h5.ophyer.cn/official_website/other/storeHome1-4.png',title:'全球无忧卖货',tip:'打通物流、清关、综合服务等能力，帮助商家接入海外高流量平台，支持本地化运营'},
    {img:'https://h5.ophyer.cn/official_website/other/storeHome1-5.png',title:'社交分销带货',tip:'帮助您自建分销体系，提供京东商品供应链。佣金设置灵活，分销商管理高效。数万优质社群推客。KOL裂变运营，人人分销，让更多的人帮您卖货。'}
  ],
  'server':[
    {img:require('../../public/img/icons/store/Store-1.png'),title:'扫码咨询',content:'扫码咨询迅速响应 <br/>让你不再等待'},
    {img:require('../../public/img/icons/store/Store-2.png'),title:'售后服务',content:'1V1客服答疑<br/> 7X24h技术支持'},
    {img:require('../../public/img/icons/store/Store-3.png'),title:'生态服务',content:'专业代运营服务 <br/>产业园区资源'}
  ],
  'introduction':[
    {title:'三秒开店',img:'https://h5.ophyer.cn/official_website/other/store-1.png'},
    {title:'智能投放',img:'https://h5.ophyer.cn/official_website/other/store-2.png'},
    {title:'全球卖货',img:'https://h5.ophyer.cn/official_website/other/store-3.png'}
  ],
  'flowList':[
    {title:'变现难',img:require('../../public/img/icons/store/storeSolution1-1.png'),p:'您有流量但需寻求电商变现'},
    {title:'开发慢',img:require('../../public/img/icons/store/storeSolution1-2.png'),p:'自建商城开发难、成本高、更新慢'},
    {title:'无货源',img:require('../../public/img/icons/store/storeSolution1-3.png'),p:'无货源，缺乏优质的进货渠道'},
    {title:'运营难',img:require('../../public/img/icons/store/storeSolution1-4.png'),p:'运营难，无法快速地把流量变现<br/> 并提升复购'},
  ],
  'scenes':[
    {img:'https://h5.ophyer.cn/official_website/other/storeSolution3-1.png',title:'APP',content:'通过嵌入微商城售卖周边商品，深挖用户价值',style:'background:linear-gradient(121deg, #E77B1B, #FFD87C)'},
    {img:'https://h5.ophyer.cn/official_website/other/storeSolution3-2.png',title:'小程序',content:'在商家的小程序内快速搭建微商城，打造交易能力',style:'background:linear-gradient(121deg, #34B9FE, #7EF3FE)'},
    {img:'https://h5.ophyer.cn/official_website/other/storeSolution3-3.png',title:'社交媒体',content:'面向达人+明星+自媒体+品牌，在社交媒体快速地流量变现',style:'background:linear-gradient(180deg, #2574FC, #4DDEFF)'}
  ],
  'swiperOption': {
    slidesPerView: 4,
    spaceBetween: 25,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  },
  'capability':[
    {img:require('../../public/img/icons/store/storeSolution4-1.png'),title:'账号互通',content:'无需重复登录商城，即可体验一站式<br/>电商运营服务'},
    {img:require('../../public/img/icons/store/storeSolution4-2.png'),title:'多支付渠道',content:'多种支付渠道，高效对接微信、银行<br/>卡等主流支付方式'},
    {img:require('../../public/img/icons/store/storeSolution4-3.png'),title:'能力定制',content:'针对个性化场景，支持定制开发，满<br/>足差异化需求'},
    {img:require('../../public/img/icons/store/storeSolution4-4.png'),title:'分销市场',content:'多渠道分销机制，多层级分销体系,<br/>为商家带来更多销量'},
    {img:require('../../public/img/icons/store/storeSolution4-5.png'),title:'商品管理',content:'轻松高效管理商品，包括上/下<br/>架、一键搬家、商品导入等'},
    {img:require('../../public/img/icons/store/storeSolution4-6.png'),title:'个性化主题',content:'提供商城装修能力，包括主题设计、<br/>智能抠图、海量模板等'},
    {img:require('../../public/img/icons/store/storeSolution4-7.png'),title:'订单管理',content:'提供订单查询、评价管理、批量发<br/>货、售后等功能'},
    {img:require('../../public/img/icons/store/storeSolution4-8.png'),title:'运营管理',content:'提供多场景运营方式，包括新人价<br/>、拼团、砍价等，完成拉新及复购'},
    {img:require('../../public/img/icons/store/storeSolution4-9.png'),title:'积分商城',content:'为您的应用搭建积分商城，提升用<br/>户粘性'},
    {img:require('../../public/img/icons/store/storeSolution4-10.png'),title:'数据分析',content:'分析流量、用户、订单等数据，<br/>帮助您完成精细化运营'},
  ],
}

export default {
  IP,
  AI,
  OnlineRetailersStore,
}